import React, { useState } from "react";
import {
  Modal,
  Grid,
  Card,
  Typography,
  Divider,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const AccessModal = ({ isOpen, setIsOpen, employeeId, setEmployeeId, onUpdate }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      worksPortal: false,
      hrPortal: false,
      accountsPortal: false,
      clientPortal: false,
      inventoryPortal: false,
      showcasePortal: false,
      superAdmin: false,
    },
  });

  const closeModal = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    console.log("Permissions Data:", data);
    onUpdate(data); 
    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", padding: 20 }}
      >
        <Card
          sx={{
            p: 4,
            width: "60%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Access & Permissions
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="worksPortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Works Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="clientPortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Client Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="hrPortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="HR Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="inventoryPortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Inventory Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="accountsPortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Accounts Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="showcasePortal"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Showcase Portal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="superAdmin"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Super Admin"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="error" onClick={closeModal}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Modal>
  );
};

export default AccessModal;
