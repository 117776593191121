import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from '@mui/material';
import { useLocation, Link, matchPath } from 'react-router-dom';
import { Scrollbar } from './scrollbar';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import { useAuth } from '../hooks/use-auth';
import DescriptionIcon from '@mui/icons-material/Description';
import GridViewIcon from '@mui/icons-material/GridView';
import RuleIcon from '@mui/icons-material/Rule';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StarIcon from '@mui/icons-material/Star';
import "../Custom.css"
import { CoPresent,GifBox,GifOutlined,GifTwoTone } from '@mui/icons-material';

const items = [
  {
    icon: GroupsIcon,
    title: 'Employees',
    href: '/dashboard/employees',
  },
  {
    icon: BusinessIcon,
    title: 'Department',
    href: '/dashboard/departments',
  },
  {
    icon: BusinessIcon,
    title: 'Employee Evaluation Templates',
    href: '/dashboard/employee-evaluation-templates',
  },
  {
    icon: GridViewIcon,
    title: 'Document Category',
    href: '/dashboard/documentcategory',
  },
  {
    icon: DescriptionIcon,
    title: 'Documents',
    href: '/dashboard/documents',
  },
  {
    icon: RuleIcon,
    title: 'Attendance',
    href: '/dashboard/attendance',
    submenu: [
      {
        title: 'Attendance Report',
        href: '/dashboard/attendance/attendance-report',
      },
      {
        title: 'Holiday Calendar',
        href: '/dashboard/attendance/holiday-calendar',
      },
      {
        title: 'Leave Application',
        href: '/dashboard/attendance/leave-application',
      },
      {
        title: 'Leave Managment',
        href: '/dashboard/attendance/leave-management',
      },
      {
        title: 'Leave Ledger',
        href: '/dashboard/attendance/leave-eligibility',
      },
      {
        title: 'Not Signed In',
        href: '/dashboard/attendance/not-signed',
      },
    ],
  },


  {
    icon: NotificationsActiveIcon,
    title: 'Notifications',
    href: '/dashboard/notifications',
  },
  {
    icon: CurrencyRupeeIcon,
    title: 'Salary Management',
    href: '/dashboard/salarymanagement',
  },

  {
    icon: StarIcon,
    title: 'Employee Rating Parameters',
    href: '/dashboard/employee-rating-paramters',
  },

  {


    icon: CoPresent,
    title: 'Employee Roles',
    href: '/dashboard/employee-role',
  },

  {


    icon: WorkspacePremiumIcon,
    title: 'Reward Programs',
    href: '/dashboard/reward-programs',
  },
  {
    icon: GifTwoTone,
    title: 'Award Winners',
    href: '/dashboard/award-winners',
  },
  {
    icon: GifBox,
    title: 'Award Category',
    href: '/dashboard/award-category',
  }

];

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [attendanceExpanded, setAttendanceExpanded] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    items.forEach((item) => {
      const active = !!matchPath({ path: item.href, end: true }, pathname);
      if (active) {
        setActiveItem(item);
      }
    });
  }, [pathname]);

  const logoutHandler = () => {
    auth.logout();
  };
  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown);
  };


  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { },
        onMouseLeave: () => { },
        sx: {
          backgroundColor: 'background.paper',
          height: '100%',
          overflowX: 'hidden',
          transition: 'width 250ms ease-in-out',
          backgroundColor: 'white',
          width: pinned ? 260 : 73,
          '& .simplebar-content': {
            height: '100%',
          },
          '&:hover': {
            width: 260,
            '& span, p': {
              display: 'flex',
            },
          },
        },
      }}
    >
      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: 20, paddingLeft: 15 }}>
        <Avatar sx={{ backgroundColor: 'rgb(42,196,171)', color: 'white', width: '26px', height: '26px' }}>HR</Avatar>
        <Typography variant="body1" style={{ marginLeft: 5, fontWeight: 600, fontSize: 15 }}>HR CRM</Typography>
      </div>
      <Divider sx={{ color: 'gray', mt: "18px" }} />
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2,
            pt: 3
          }}
        >
          <List disablePadding>
            {items.map((item) => (
              <React.Fragment key={item.title}>
                <Link to={item.href} style={{ textDecoration: 'none', color: 'rgb(133,150,160)', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}>
                  <ListItem
                    sx={{ backgroundColor: activeItem?.title === item.title ? 'rgb(234,248,244)' : '', borderRadius: 2 }}
                    onClick={item.submenu ? handleDropdownToggle : null}
                  >


                    <ListItemIcon className="custom-list-icon">
                      <item.icon className='dash-icons' sx={{ color: activeItem?.title === item.title ? 'rgb(63,197,149)' : 'rgb(111,132,143)' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography style={{ fontWeight: 500, fontSize: '14px', color: activeItem?.title === item.title ? 'rgb(63,197,149)' : 'rgb(103,123,133)' }}>
                        {item.title}
                      </Typography>
                    </ListItemText>
                    {/* Down arrow button */}

                    {item.submenu ? (
                      openDropdown && activeItem?.title === item.title ? (
                        <ArrowDropUpIcon onClick={handleDropdownToggle} />
                      ) : (
                        <ArrowDropDownIcon onClick={handleDropdownToggle} />
                      )
                    ) : null}
                  </ListItem>
                </Link>
                {/* Render submenu items */}
                {openDropdown && activeItem?.title === item.title && item.submenu ? (
                  <List disablePadding sx={{ backgroundColor: 'rgb(234,248,244)', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, bottom: 7, position: 'relative', paddingTop: 1 }} >
                    {item.submenu.map((dropdownItem) => (

                      <Link class="nav-list" Link
                        to={dropdownItem.href}
                        key={dropdownItem.title}
                        style={{ textDecoration: 'none', color: 'rgb(133,150,160)', justifyContent: 'flex-start', whiteSpace: 'nowrap', }}
                      >
                        <ListItem
                          sx={{ color: activeItem?.title === item.title ? 'blue' : '' }}

                        >

                          <Typography
                            style={{
                              color: pathname === dropdownItem.href ? 'rgb(63,197,149)' : 'rgb(103,123,133)',
                              fontSize: '14px',
                              paddingLeft: '14px'
                            }}
                          >{dropdownItem.title}</Typography>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                ) : null}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Scrollbar>
      <Box sx={{ pt: 2 }}>
        <IconButton onClick={onPin}>
          {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
    </Drawer >
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
};
