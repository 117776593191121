import { Suspense, lazy } from 'react';
import { GuestGuard } from "./guards/GuestGuard";
import { LoadingScreen } from './components/loading-screen';
import AuthGuard from "./guards/AuthGuard";
import { DashboardLayout } from './containers/dashboard-layout';
import { Navigate, Outlet } from "react-router-dom";
import ForgotPassword from './components/login/forgot-password';


//  Dashboard Menu's
import Employees from './project-portal/pages/DashboardMenus/employees';
import Department from './project-portal/pages/DashboardMenus/departments';
import Documents from './project-portal/pages/DashboardMenus/documents';
import Attendance from './project-portal/pages/DashboardMenus/attendance';
import AttendanceReport from './project-portal/pages/DashboardMenus/attendance/submenus/AttendanceReport';
import DocumentCategory from './project-portal/pages/DashboardMenus/DocumentCategory';
import Notifications from './project-portal/pages/DashboardMenus/Notifications';
import SalaryManagement from './project-portal/pages/DashboardMenus/SalaryManagement';
import HOlidayCalendar from './project-portal/pages/DashboardMenus/attendance/submenus/HolidayCalendar';
import LEaveManagement from './project-portal/pages/DashboardMenus/attendance/submenus/LeaveManagement';
import NotSigned from './project-portal/pages/DashboardMenus/attendance/submenus/NotSigned';
import EmployeeRatingParameters from './project-portal/pages/DashboardMenus/EmployeeRatingParameter';
import EmployeeRoles from './project-portal/pages/DashboardMenus/EmployeeRoles';
import LeaveEligibility from './project-portal/pages/DashboardMenus/attendance/submenus/leaveEgilibilty/LeaveEligibility';
import RewardPrograms from './project-portal/pages/DashboardMenus/RewardPrograms'
import EmployeeEvaluationTemplate from './project-portal/pages/DashboardMenus/employee evaluation template';
import AwardWinners from './project-portal/pages/DashboardMenus/award-winners';
import AwardCategory from './project-portal/pages/DashboardMenus/award-category';
import LeaveApplication from './project-portal/pages/DashboardMenus/attendance/submenus/leaveApplication/LeaveApplication';

const Loadable = (Component) => (props) => (
    <Suspense  fallback={<LoadingScreen />}>
        <Component {...props}  />
    </Suspense>
);



// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));




const routes = [
    {
        path: '',
        element: (
            <GuestGuard>
                <Login />
            </GuestGuard>
        )
    },
    {
        path: 'forgot-password',
        element: (
            <GuestGuard>
                <ForgotPassword />
            </GuestGuard>
        )
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: (
                    <Navigate
                        to="/dashboard/employees"
                        replace
                    />
                )
            },

            {
                path: 'employees',
                element: <Employees />
            },
            {
                path: 'departments',
                element: <Department />
            },
            {
                path: 'employee-evaluation-templates',
                element: <EmployeeEvaluationTemplate />
            },
            {
                path: 'documents',
                element: <Documents />
            },
            {
                path: 'attendance',
                element: (
                    <>
                        <Outlet />

                    </>),
                children: [

                    {
                        path: "",
                        element: <Attendance />
                    },
                    {
                        path: 'attendance-report',
                        element: <AttendanceReport />
                    },
                    {
                        path: 'holiday-calendar',
                        element: <HOlidayCalendar />
                    },
                    {
                        path: 'leave-management',
                        element: <LEaveManagement />
                    },
                    {
                        path: 'leave-eligibility',
                        element: <LeaveEligibility />
                    },
                    {
                        path: 'leave-application',
                        element: <LeaveApplication />
                    },
                    {
                        path: 'not-signed',
                        element: <NotSigned />
                    },

                    // {
                    //     path: "Invalid",
                    //     element: <Invalid />,
                    // },
                    // {
                    //     path: "lost",
                    //     element: <Lost />,
                    // },
                    // {
                    //     path: "future",
                    //     element: <Future />,
                    // },
                ],
            },

            {
                path: 'documentcategory',
                element: <DocumentCategory />
            },
            {
                path: 'notifications',
                element: <Notifications />
            },
            {
                path: 'salarymanagement',
                element: <SalaryManagement />
            },
            {
                path: 'employee-rating-paramters',
                element: <EmployeeRatingParameters />
            },
            {
                path: "employee-role",
                element: <EmployeeRoles />
            },
            {
                path: "reward-programs",
                element: <RewardPrograms />
            },
            {
                path: "award-winners",
                element: <AwardWinners />
            },
            {
                path: "award-category",
                element: <AwardCategory />
            }

        ],

    },
    {
        path: '*',
        element: <NotFound />
    }


];

export default routes;
