import React, { useEffect, useState } from 'react';
import moment from "moment";

import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import PageHeader from '../../../common/page-header';
import { Employee } from '../../../../../api/Endpoints/Employee';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { WaveformSpinner } from '../../../../../components/Loader';
import { Fragment } from 'react';
import SelectX from '../../../../form/SelectX';
import { fetchEmployees } from '../../../../CommonFunction/Index';
import SearchField from '../../../../../components/searchEntry/Index'
import { useForm } from 'react-hook-form';
const NotSigned = () => {
    const { watch, formState: { errors }, control, setValue, reset } = useForm();

    //Date picker state

    const [listNotSignedIn, setListNotSignedIn] = useState([]);
    const [date, setDate] = useState(dayjs(new Date()))
    const [loading, setLoading] = useState(false);
    const [serachEntry, setSearchEntry] = useState('')
    const [searchKeyword, setSearchKeyword] = useState();
    const [from, setFrom] = useState(dayjs(new Date()));
    const [to, setTo] = useState(dayjs(new Date()));

    const fetchTable = () => {
        setLoading(true)
        Employee.getNotSignedInList({ date: date.format('YYYY-MM-DD') }).then(response => {
            setListNotSignedIn(response.data.data);
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchTable();
    }, [date])


    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)

    }

    const clearSerachInput = () => {
        setSearchEntry('');
        setSearchKeyword()
    }

    const resetHandler = () => {
        reset()
        setFrom(dayjs(new Date()))
        setTo(dayjs(new Date()))
        setValue('employee', null)
        clearSerachInput()
    }

    return (
        <>
            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1,ml:2, boxShadow: 0 }}
                className="page_header">
                <PageHeader title={"Not Signed In"} total={listNotSignedIn.length && !loading && listNotSignedIn.length} />

            </Card >


            <Card sx={{ m: 2 }} variant="outlined">
                <Grid container justifyContent="space-between" m={2} alignItems="center">
                    <Grid width={'30%'} pt={1}>
                        <SelectX
                            options={fetchEmployees}
                            placeholder="Employee"
                            control={control}
                            name="employee"
                        />
                    </Grid>

                    <Grid item xs={8} >
                        <Grid container alignItems="center" justifyContent={'end'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={3} ml={2}>
                                    <DatePicker
                                        value={from}
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                variant="outlined"
                                                label="From"
                                                sx={{ maxWidth: "100%" }} />
                                        )}
                                        onChange={(newValue) => setFrom(newValue)}
                                        inputFormat="DD-MM-YYYY"
                                    />
                                </Grid>
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={3} ml={1}>
                                    <DatePicker
                                        value={to}
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="To" sx={{ maxWidth: "100%" }} />
                                        )}
                                        onChange={(newValue) => setTo(newValue)}
                                        inputFormat="DD-MM-YYYY"
                                    />
                                </Grid>
                            </LocalizationProvider>

                            <Grid item>
                                <Button
                                    onClick={resetHandler}
                                    className='create-btn'
                                    sx={{ color: 'white', height: 36, mr: 1 }}
                                >Reset</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
                {
                    loading ? (
                        <Fragment>
                            <Box id="ui-ball-loader" >
                                <WaveformSpinner />
                            </Box>
                        </Fragment >
                    ) : (
                        <Fragment>
                            {listNotSignedIn?.length > 0 ? (
                                <Fragment>
                                    <TableContainer component={Paper} >
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {listNotSignedIn?.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.name} </TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{date.format('DD-MM-YYYY')}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Fragment>
                            ) : (
                                <Box id='data-not-found-msg'>
                                    <Typography alignSelf={'center'}>Not Signed In not found.</Typography>
                                </Box>
                            )}
                        </Fragment>
                    )}
            </Card >





        </>
    );
};

export default NotSigned;
