import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Slide,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../../form/TextInput";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from "../../../../../form/SelectX";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import DateInput from "../../../../../form/DateInput";
import { Project } from "../../../../../../api/Endpoints/Project";
import { Employee } from "../../../../../../api/Endpoints/Employee";
import { LeaveApplications } from "../../../../../../api/Endpoints/LeaveApplication";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const approvalStatusOptions = [
  { value: "Not Reviewed", label: "Not Reviewed" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Pending", label: "Pending" }
];

const Create = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset
  } = useForm({});
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    props.onNew();
  };
  const handleClose = () => {
    props.setEditId(undefined);
    setOpen(false);
  };

  //component variables
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false
  });

  const fetchEmployees = async (keyword) => {
    const response = await Employee.get({ keyword: keyword });
    console.log(response);

    return response.data.data?.data;
  };

  const onSubmit = (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error"
    });

    let dataToSubmit = {
      id: props.editId,
      employee_id: data.employee_user_id?.id,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
      count: data.count,
      manager_id: data.manager_id?.id,
      attendance_type:data.attendance_type,
      leave_type:data.leave_type,
      date_added: moment(new Date()).format("YYYY-MM-DD")
      // hr_approval_status: data.hr_approval_status,
      // hr_review_date: moment(data.hr_review_date).format("YYYY-MM-DD"),
      // hr_remarks: data.hr_remarks,
      // manager_remarks: data.manager_remarks,
     
    };

    let action;

    console.log("dataToSubmit", dataToSubmit);
    if (props.editId > 0) {
      action = LeaveApplications.update(dataToSubmit);
    } else {
      console.log("created");

      action = LeaveApplications.add(dataToSubmit);
    }
    action
      .then((response) => {
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true
        });
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
        toast.success(response.data.message);
        props.onUpdate();
        props.setEditId();
        setOpen(false);
        setTimeout(() => {
          setAlerts({});
        }, 2000);
      })
      .catch((errors) => {
        console.log(errors);
        toast.error("Internal server error");
        setAlerts({
          active: true,
          message: "Internal server error",
          type: "error"
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
      });
  };

  
//   const fetchCount = async (startDate, endDate) => {
//     try {
//       const response = await LeaveApplications.calculateCount({
//         start_date: moment(startDate).format("YYYY-MM-DD"),
//         end_date: moment(endDate).format("YYYY-MM-DD")
//       });
  
//       if (response?.data?.status === "success") {
//         setValue("count", response.data.data.count); 
//       } else {
//         toast.error("Failed to fetch count");
//       }
//     } catch (error) {
//       console.error("Error fetching count:", error);
//       toast.error("An error occurred while fetching the count.");
//     }
//   };

// useEffect(() => {
//     const startDate = watch("start_date");
//     const endDate = watch("end_date");

//     if (startDate && endDate) {
//       fetchCount(startDate, endDate);
//     }
//   }, [watch("start_date"), watch("end_date")]); 
   
  useEffect(() => {
     if (props.editId === 0) {
      reset();
      setOpen(true);
    }
  }, [props.editId]);

  useEffect(() => {
    setRefresh(Math.random());
  }, []);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          />
        ) : (
          "Apply Leave"
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%"
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0
            ? "Edit Leave application"
            : "Apply Leave"}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Error Messages */}
              <Box mb={2}>
                <ErrorMessage
                  errors={errors}
                  name="multipleErrorInput"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => ({
                      message
                    }))
                  }
                />
              </Box>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <SelectX
                    label="Employee"
                    options={fetchEmployees}
                    control={control}
                    error2={errors?.employe?.message || false}
                    name="employee_user_id"
                    defaultValue={watch("employee_user_id")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectX
                    label="Manager"
                    options={fetchEmployees}
                    control={control}
                    error2={errors?.manager?.message || false}
                    name="manager_id"
                    defaultValue={watch("manager_id")}
                  />
                </Grid>
              </Grid>

              {/* Date Inputs */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={4}>
                  <DateInput
                    control={control}
                    name="start_date"
                    label="Start Date"
                    value={watch("start_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateInput
                    control={control}
                    name="end_date"
                    label="End Date"
                    value={watch("end_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel sx={{color:"black"}}>Count</InputLabel>
                  <TextInput
                    name="count"
                    type="number"
                    control={control}
                    value={watch("count") }
                    size="small" 
                    // InputProps={{
                    //     readOnly: true,
                    //   }}
                  />
                </Grid>
              </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  
                    <InputLabel
                      sx={{
                        color: "black"
                      }}
                    >
                      Attendance Type
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="leave-type-label"
                        id="leave-type"
                        value={watch("attendance_type") || ""}
                        onChange={(e) => setValue("attendance_type", e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="Full Day">Full Day</MenuItem>
                        <MenuItem value="Half Day">Half Day</MenuItem>
                      </Select>
                      <ErrorMessage
                        errors={errors}
                        name="leave_type"
                        render={({ message }) => (
                          <p style={{ color: "red" }}>{message}</p>
                        )}
                      />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ color: "black" }}>Leave Type</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="leave-category-label"
                      id="leave-category"
                      value={watch("leave_type") || ""}
                      onChange={(e) =>
                        setValue("leave_type", e.target.value)
                      }
                      displayEmpty
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Casual Leave">Casual Leave</MenuItem>
                      <MenuItem value="Sick Leave">Compensatory Leave</MenuItem>
                      <MenuItem value="Special Leave">Special Leave</MenuItem>
                      {/* <MenuItem value="Religious Leave">Special Leave</MenuItem> */}
                    </Select>
                    <ErrorMessage
                      errors={errors}
                      name="leave_type"
                      render={({ message }) => (
                        <p style={{ color: "red" }}>{message}</p>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <LoadingButton
                    loading={formButtonStatus.loading}
                    disabled={formButtonStatus.disabled}
                    variant="contained"
                    type="submit"
                  >
                    {formButtonStatus.label}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Create;
