import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, InputLabel, Slide } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import SelectX from '../../../../form/SelectX'
import TextInput from '../../../../form/TextInput'
import DateInput from '../../../../form/DateInput'
import { Departments } from '../../../../../api/Endpoints/Departments';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import moment from 'moment';
import ReactSelector from 'react-select';
import "../../../../../Custom.css"
import { DotSpinner } from '@uiball/loaders';
import { fetchDepartments, fetchEmployeeRoles, fetchEmployees } from '../../../../CommonFunction/Index';
import { EmployeeRole } from '../../../../../api/Endpoints/EmployeeRoles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({
    name: yup.string().required("field is requried"),
    // phone_number: yup.string(),
    // employee_roles_id: yup.object().required("field is requried"),
    // departments_id: yup.object().required("field is requried"),
    // address: yup.string().required("field is requried"),
    // emergency_contacts: yup.string(),
    // joining_date: yup.date().required("field is requried"),
    // releving_date: yup.date().required("field is requried"),
    // personal_email: yup.string().required("field is requried"),
    // work_email: yup.string().required("field is requried"),
    // remarks: yup.string(),
});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });

    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([])

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    console.log(checked)


    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked); 
    };

    const handleClose = () => {
        setOpen(false)
        reset()
        // setChecked(false); 
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)

    }


    const handleClickOpen = () => {
        props.onNew()
        // setChecked(false); 

    }
// fetch employee roles 
// const fetchRole =async () => {
//     setLoading(true);
//     let allRoles = [];
//     let currentPage = 1;
//     let hasMorePages = true;

//     while (hasMorePages) {
//       try {
//         const response = await EmployeeRole.get({ page: currentPage });
//         // console.log(response.data.data)
//         const pageData = response.data.data;
//         allRoles = [...allRoles, ...pageData.data];
//         if (pageData.current_page < pageData.last_page) {
//           currentPage++;
//         } else {
//           hasMorePages = false;
//         }
//       } catch (error) {
//         console.error("Error fetching roles:", error);
//         hasMorePages = false;
//       }
//     }

//     setRoles(allRoles);
//     setLoading(false);
//   };
// console.log(roles)
// useEffect(() => {
//     fetchRole()
// },[])


    const Status = [
        { value: "Trainee", label: 'Trainee' },
        { value: "Intern", label: 'Intern' },
        { value: "Executive", label: 'Executive' },
        { value: "Manager", label: 'Manager' },
        { value: "Senior Executive", label: 'Senior Executive' },
        { value: "Director", label: 'Director' },
        { value: "Senior Manager", label: 'Senior Manager' },
        { value: "General Manager", label: 'General Manager' },


    ];
    const EmployeeTy = [
        { value: "Consultant", label: 'Consultant' },
        { value: "Employee", label: 'Employee' },
        { value: "Ex-Employee", label: 'Ex-Employee' },

    ]
    
    const handleFormSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let JoinDate = moment(data.joining_date).format('YYYY-MM-DD');
        let DOB = moment(data.date_of_birth).format('YYYY-MM-DD');
        let official_date_of_birth = moment(data.official_date_of_birth).format('YYYY-MM-DD');
        let marriage_date = moment(data.marriage_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props?.editId,
            users_id: watch('user_id'),
            email: data.personal_email,
            personal_email: data.personal_email,
            employee_office_id: data.employee_id,
            employee_id: data.employee_id,
            // email: data.personal_email,
            personal_email: data.personal_email,
            username: data.name,
            name: data.name,
            address: data.address,
            phone_number: data.phone_number,
            employment_status: data.employment_status,
            official_date_of_birth: official_date_of_birth,
            marriage_date: marriage_date,
            releaving_date: data.releaving_date,
            employee_level: data.employee_level,
            phone_number: data.phone_number,
            departments_id: data.department?.id,
            managers_id: data.manager?.id,
            email: data.work_email,
            emergency_contacts: data.emergency_contacts,
            parant_organisations_id: 35, //Spiderworks India
            // employee_role_id: data.employee_role_id?.id,
            employee_roles_id: data.employee_roles_id?.id,
            address: data.address,
            joining_date: JoinDate,
            date_of_birth: DOB,
            remarks: data.remarks,
            facebook_url: data.facebook_url,
            linkedin_url: data.linkedin_url,
            instagram_url: data.instagram_url,
            blog_url: data.blog_url,
            reporting_email: data.reporting_email,
            is_signin_mandatory: checked ? 1 : 0
        };
        console.log(dataToSubmit)
        let action;
        if (data.work_email || data.personal_phone) {
            const ExtraUpdate = {
                users_id: watch('user_id')
            };

            if (data.work_email) {
                ExtraUpdate.email = data.work_email;
                action = Employee.updateUserEmail(ExtraUpdate);
                ExtraUpdate.phone = data.personal_phone;
                action = Employee.updatePersonalPhone(ExtraUpdate);
            }
        }

        if (props.editId > 0) {
            console.log("this datatosubmit", dataToSubmit);
            action = Employee.update(dataToSubmit);
        } else {
            action = Employee.add(dataToSubmit);

        }

        action.then(response => {
            if (response.data.errors) {
                toast.error("The email or phone number has already been taken")
                console.log(response.data)
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                return;
            }

            toast.success(response.data.message, { autoClose: 3000 })
            props.onUpdate();
            console.log("sucess",response.data.message)
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            handleClose()

        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        })
    }
    useEffect(() => {
        if (props.editId > 0) {
            fetchDetails()
            setOpen(true)
        } else if (Number(props.editId) == 0) {
            setOpen(true);
        }

    }, [props.editId])



    const fetchDetails = async () => {
        setLoading(true)
        let EmployeeDetail = await Employee.getEmployeeDetails({ id: props.editId });
        if (EmployeeDetail.data.status === "success") {
            let data = EmployeeDetail.data.data;
            let user = EmployeeDetail.data.data.user
            setValue('name', data.user.name);
            setValue('employee_id', data.employee_id);
            setValue('work_email', data.user?.email);
            setValue('personal_email', data.email);
            setValue('user_id', data.users_id)
            setValue('department', data.department);
            setValue('employee_office_id', data.employee_id)
            setValue('official_date_of_birth', data.official_date_of_birth)
            setValue('manager', user?.manager)
            setValue('marriage_date', data.marriage_date)
            setValue('date_of_birth', data.date_of_birth)
            setValue('releaving_date', data.releaving_date)
            setValue('phone_number', data.phone_number)
            setValue('personal_phone', data.user?.username)
            setValue('username', user?.username)
            // setValue('employee_role', data.role);
            setValue('employee_roles_id', data.role);
            setValue('address', data.address);
            setValue('emergency_contacts', data.emergency_contacts);
            setValue('joining_date', data.joining_date);
            setValue('remarks', data.remarks);
            setValue('employment_status', data.employment_status);
            setValue('date_of_birth', data.date_of_birth);
            setValue('employee_level', data.employee_level);
            setValue('releaving_date', data.releaving_date);
            setValue('facebook_url', data.user?.facebook_url);
            setValue('linkedin_url', data.user?.linkedin_url);
            setValue('instagram_url', data.user?.instagram_url);
            setValue('blog_url', data.user?.blog_url);
            setValue('reporting_email', data.user?.reporting_email)
            setChecked(data.is_signin_mandatory)

        }


        setLoading(false)


    }
    const roleOptions = roles.map(role => ({
        label: role.name,
        value: role.name,
        id: role.id
      }));

      const employeeRoles = [...roleOptions]
console.log(employeeRoles)

    return (
        <div>
            <Button
                className='create-btn'
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Add a margin to the icon if needed */}
                {props.changeText || (
                    <>
                        <AddIcon sx={{ marginRight: '2px' }} />
                        Add Employee
                    </>
                )}

            </Button>

            <Dialog
                open={open}
                PaperProps={{
                    sx: { width: "43%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle sx={{ pt: 1, pl: 1 }}>{parseInt(props.editId) > 0 ? "Edit Employee" : "Create Employe"}</DialogTitle>
                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <DotSpinner
                            size={47}
                            speed={0.9}
                            color="rgb(63,197,149"
                        />
                    </Box >
                ) : (
                    <>
                        <DialogContent DialogContent sx={{ p: 2 }}>


                            <Grid >
                                <Grid item xs={12} sm={12}>
                                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                                        <Grid >
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextInput
                                                        control={control}
                                                        name="name"
                                                        label="Name"
                                                        value={watch('name')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>

                                                    <TextInput control={control} name="employee_id"
                                                        label="Employee ID"
                                                        value={watch('employee_id')}
                                                         />

                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={6}>
                                                    <TextInput control={control} name="personal_email"
                                                        label="Personal Email"
                                                        value={watch('personal_email')} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextInput control={control} name="work_email"
                                                        label="Work Email"
                                                        value={watch('work_email')} />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={6}>
                                                    <TextInput
                                                        control={control}
                                                        name="personal_phone"
                                                        label="Personal Phone"
                                                        value={watch('personal_phone')}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextInput
                                                        control={control}
                                                        name="phone_number"
                                                        label="Office Phone"
                                                        value={watch('phone_number')}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={2}>

                                                <Grid item xs={6}>
                                                    <DateInput
                                                        control={control}
                                                        name="official_date_of_birth"
                                                        label="Official Date of Birth"
                                                        value={watch('official_date_of_birth')}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <DateInput
                                                        control={control}
                                                        name="date_of_birth"
                                                        label="Celebrated Date"
                                                        value={watch('date_of_birth')}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={12}>
                                                    <DateInput
                                                        control={control}
                                                        name="marriage_date"
                                                        label="Marriage Date"
                                                        value={watch('marriage_date')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} mt={2}>

                                                <Grid sx={{ p: 1 }} item xs={6}>
                                                    <DateInput
                                                        control={control}
                                                        name="joining_date"
                                                        label="Joining Date"
                                                        value={watch('joining_date')}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={6}>
                                                    <DateInput
                                                        control={control}
                                                        name="releaving_date"
                                                        label="Relieaving Date"
                                                        value={watch('releaving_date')}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={6} sm={6}>
                                                    <InputLabel sx={{
                                                        color: "black",
                                                    }}>Employee Level</InputLabel>
                                                    <ReactSelector
                                                        options={Status}
                                                        value={Status.filter(requestoptions => requestoptions.label === watch('employee_level'))}
                                                        name='employee_level'
                                                        defaultValue={watch('employee_level')}
                                                        isClearable="true"
                                                        onChange={(selectedOption) => setValue('employee_level', selectedOption?.value || "")}
                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}



                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <InputLabel sx={{
                                                        color: "black",
                                                    }}>Employee Type</InputLabel>
                                                    <ReactSelector
                                                        options={EmployeeTy}
                                                        value={EmployeeTy.filter(requestoptions => requestoptions.label === watch('employment_status'))}
                                                        name='employment_status'
                                                        defaultValue={watch('employment_status')}
                                                        isClearable="true"
                                                        onChange={(selectedOption) => setValue('employment_status', selectedOption?.value || "")}
                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={6} sm={6}>
                                                    <SelectX
                                                        defaultOptions
                                                        label={"Department"}
                                                        options={fetchDepartments}
                                                        control={control}
                                                        error={errors.message}
                                                        name={'department'}
                                                        defaultValue={watch('department')}
                                                    />
                                                </Grid>


                                                <Grid item xs={6} sm={6}>
                                                    {/* <InputLabel sx={{
                                                        color: "black",
                                                    }}>Roles</InputLabel> */}
                                                    <SelectX
                                                        defaultOptions
                                                        label={"Role"}
                                                        options={fetchEmployeeRoles}
                                                        control={control}
                                                        error={errors.message}
                                                        name={'employee_roles_id'}
                                                        defaultValue={watch('employee_roles_id')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={12} sm={6}  >
                                                    <SelectX control={control}
                                                        name="manager"
                                                        label="Manager"
                                                        options={fetchEmployees}
                                                        defaultValue={watch('manager')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}  >
                                                    <SelectX control={control}
                                                        ismultiple
                                                        name="additional_manager"
                                                        label="Additional Managers"
                                                        options={fetchEmployees}
                                                        defaultValue={watch('additional_manager')}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={12} sm={6}>

                                                    <TextInput control={control} name="facebook_url"
                                                        label="Facebook URL"
                                                        value={watch('facebook_url')} />

                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextInput control={control} name="instagram_url"
                                                        label="Instagram URL"
                                                        value={watch('instagram_url')} />

                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextInput control={control} name="linkedin_url"
                                                        label="Linkedln URL"
                                                        value={watch('linkedin_url')} />
                                                </Grid>

                                                <Grid item xs={12} sm={6} >
                                                    <TextInput control={control} name="blog_url"
                                                        label="Blog URL"
                                                        value={watch('blog_url')} />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={1} mt={1}>
                                                <Grid item xs={12}>
                                                    <TextInput control={control}
                                                        name="address" isMultiline
                                                        label="Address"
                                                        row={5}
                                                        value={watch('address')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        control={control}
                                                        name="remarks"
                                                        isMultiline
                                                        label="Remarks"
                                                        row={3}
                                                        value={watch('remarks')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        control={control}
                                                        name="reporting_email"
                                                        label="Reporting Emails"
                                                        value={watch('reporting_email')} />
                                                </Grid>
                                                {/*TODO -backend fields  */}
                                                {/* <Grid container spacing={1} mt={2}> */}
                                                <Grid item xs={12} sm={12}>
                                                    <TextInput control={control} name="late_signin_emails"
                                                        label="Late Sign In Emails"
                                                        value={watch('')} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} >
                                                    <TextInput control={control} name="late_signout_mails"
                                                        label="Late Sign Out Emails"
                                                        value={watch('late_signout_mails')} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} >
                                                    <TextInput control={control} name="leave_notifications"
                                                        label="Leave Notifiction Mails"
                                                        value={watch('leave_notifications')} />
                                                </Grid>

                                            </Grid>
                                            {/* </Grid> */}

                                            <Grid mt={2}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox
                                                    checked={checked}
                                                    // onClick={() => setChecked(!checked)}
                                                    onChange={handleCheckboxChange}
                                                     />} label="Sign In is not mandatory for this employee." />
                                                </FormGroup>
                                            </Grid>
                                            <DialogActions sx={{ pr: 0 }}>
                                                <Button onClick={() => handleClose()} variant='outlined' color='error'>Close</Button>
                                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>

                                            </DialogActions>


                                        </Grid>
                                    </form>
                                </Grid>


                            </Grid>

                        </DialogContent>
                    </>
                )
                }
            </Dialog >



        </div >
    );
};

export default Create;
