import React, { useState } from "react";
import { Modal, Grid, Card, Typography, FormControl, InputLabel,FormLabel,RadioGroup, TextField, Button,FormControlLabel,Radio } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LeaveApplications } from "../../../../../../api/Endpoints/LeaveApplication";
import moment from "moment";
import TextInput from "../../../../../form/TextInput";

const approvalStatusOptions = [
    { value: "Not Reviewed", label: "Not Reviewed" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" }
];

const ApproveLeaveModal = ({ isOpen, setIsOpen,approveId,setApproveId,onUpdate }) => {
    const { control, watch,handleSubmit } = useForm();
      const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false
      });
      const [alerts, setAlerts] = useState({
        type: "",
        message: "",
        active: false
      });
    
const closeModal = ()=> {
    setIsOpen(false);
}
const onSubmit = (data)=> {
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error"
    });

    let dataToSubmit = {
      id: approveId,
      hr_approval_status: data.hr_approval_status,
      hr_review_date:moment( new Date()).format("YYYY-MM-DD"),
      hr_remarks: data.hr_remarks,
    };

    let action;

    console.log("dataToSubmit", dataToSubmit);
    if (approveId > 0) {
      action = LeaveApplications.update(dataToSubmit);
    } 
    
    action
      .then((response) => {
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true
        });
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
        toast.success(response.data.message);
        onUpdate();
        setApproveId();
        closeModal()
        setTimeout(() => {
          setAlerts({});
        }, 2000);
      })
      .catch((errors) => {
        console.log(errors);
        toast.error("Internal server error");
        setAlerts({
          active: true,
          message: "Internal server error",
          type: "error"
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
      });
}
    return (
        <Modal open={isOpen} onClose={closeModal}>
            <Grid 
                container 
                justifyContent="center" 
                alignItems="center" 
                style={{ height: "100vh", padding: 20 }}
            >
                <Card 
                    sx={{ 
                        p: 4, 
                        width: "60%", 
                        maxWidth: "800px", 
                        bgcolor: "background.paper", 
                        boxShadow: 24 
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Approve Leave
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                <FormControl fullWidth sx={{ pt: 2 }}>
                  <FormLabel>Hr Approval Status</FormLabel>
                  <Controller
                    name="hr_approval_status"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup {...field} sx={{ flexDirection: "row" }}>
                        {approvalStatusOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
  <InputLabel>HR Remarks</InputLabel>
  <Controller
    name="hr_remarks"
    control={control}
    defaultValue=""
    render={({ field }) => (
      <TextField
        {...field}
        multiline
        fullWidth
        rows={4}
        variant="outlined"
      />
    )}
  />
</Grid>

                        </Grid>
                   
                    <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Grid item>
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="error" onClick={closeModal}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    </form>
                </Card>
            </Grid>
        </Modal>
    );
};

export default ApproveLeaveModal;
