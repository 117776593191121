import React, { useEffect, useState } from 'react';
// import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { WaveformSpinner } from '../../../../components/Loader';
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider, Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    InputAdornment, IconButton, Box
} from "@mui/material";
// import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from '../../common/page-header'
// import { Account } from "../../../api/Endpoints/Account";
// import { Project } from "../../../api/Endpoints/Project";
import { Employee } from '../../../../api/Endpoints/Employee'
import SearchField from '../../../../components/searchEntry/Index'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import AsyncSelect from "react-select/async";
import { Organisations } from '../../../../api/Endpoints/Organisation';
import { Padding } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DateInput from '../../../form/DateInput';
import SelectX from '../../../form/SelectX';
import { Fragment } from 'react';

const AttendanceReport = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();

    //Date picker state
    const [from, setFrom] = useState(dayjs(new Date()));
    const [to, setTo] = useState(dayjs(new Date()));
    const [user, setUser] = useState();
    // const [user, setUser] = useState(dayjs(new Date()));
    const [update, setUpdate] = useState(1);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')

    const ForTableUpdate = () => {
        setTableUpdate(true)
        fetchTable()

    }


    const fetchTable = (retryCount = 0) => {
        const maxRetries = 3;
        if (!TableUpdate) {
            setLoading(true)
        }
        Employee.getAttendanceList({
            keyword: searchKeyword, from: from.format('DD-MM-YYYY 00:00:00'), to: from.format('DD-MM-YYYY 23:59:59'), user_id: watch('employee')?.id
        }).then(response => {
            setList(response.data.data);
            setLoading(false);
        }).catch(() => {
            if (retryCount < maxRetries) {
                fetchTable(retryCount + 1);
            } else {
                setLoading(false);
                toast.error("An error occurred. Please try again later or contact the administrator.", { autoClose: 6000 })
            }
        }).finally(() => {
            if (TableUpdate) {
                setTableUpdate(false)
            }

        });
    }


    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)
        setTableUpdate(true)
    }

    const clearSerachInput = () => {
        setSearchEntry('');
        setSearchKeyword()
    }
    const handleUserSelect = (e) => {
        setUser(e.id);
    }
    const fetchEmployees = (keyword) => {
        return Employee.get({ keyword: keyword }).then(response => {
            return response.data.data?.data;
        })
    }

    const resetHandler = () => {
        reset()
        setFrom(dayjs(new Date()))
        setTo(dayjs(new Date()))
        setValue('employee', null)
        clearSerachInput()
    }

    useEffect(async () => {
        fetchTable();
    }, [watch('employee'), searchKeyword, from, to, user, update])
    return (
        <>

            {/* <PageHeader title={"Attendance report "} onCreateNew={handleCreateNew} /> */}

            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1,ml:2, boxShadow: 0 }}
                className="page_header">
                <PageHeader title={"Attendance"} total={list.length && !loading && list.length} />
            </Card >


            <Card sx={{ m: 2 }} variant="outlined">
                <Grid container justifyContent="space-between" mt={2} alignItems="center">
                    <Grid width={'30%'} pt={1}>
                        <SearchField
                            clearSerachInput={clearSerachInput}
                            serachEntry={serachEntry}
                            handleSearchEntry={handleSearchEntry}
                        />

                    </Grid>

                    <Grid item xs={7} mr={2}>
                        <Grid container alignItems="center" justifyContent={'end'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={4} mr={2}>
                                    <DatePicker
                                        value={from}
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Select Date" sx={{ maxWidth: "100%" }} />
                                        )}
                                        onChange={(newValue) => setFrom(newValue)}
                                        inputFormat="DD-MM-YYYY"
                                    />
                                </Grid>
                            </LocalizationProvider>

                            <Grid item>
                                <Button
                                    onClick={resetHandler}
                                    className='create-btn'
                                    sx={{ color: 'white', height: 36 }}
                                >Reset</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                {!TableUpdate && loading ? (
                    <Fragment>
                        <Box id="ui-ball-loader" >
                            <WaveformSpinner />
                        </Box>
                    </Fragment>
                ) : (
                    <Fragment>
                        {list?.length > 0 ? (
                            <Fragment>
                                <TableContainer component={Paper} sx={{ mt: 2 }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Sign in</TableCell>
                                                <TableCell>Sign Out</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list?.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{row.name} </TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                    <TableCell>{moment(row.created_at).format('DD-MM-YYYY')}</TableCell>
                                                    <TableCell>{moment(row.created_at).format('hh:mm A')}</TableCell>
                                                    <TableCell>{row.logged_out_time ? moment(row.logged_out_time).format('hh:mm A') : "-"}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        ) : (
                            <Box id='data-not-found-msg'>
                                <Typography alignSelf={'center'}>Attendance not found.</Typography>
                            </Box>
                        )}
                    </Fragment>)}
            </Card >


        </>
    );
};

export default AttendanceReport;
