import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmployeeRole } from '../../../../../../api/Endpoints/EmployeeRoles';
import { Employee } from '../../../../../../api/Endpoints/Employee';
import RadioInput from '../../../../../form/RadioInput';
import TextInput from '../../../../../form/TextInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import SelectX from '../../../../../form/SelectX';
import { LeaveEligibilityApi } from '../../../../../../api/Endpoints/LeaveEligibility';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object().shape({
    // role: yup.string().required(),
    // employee: yup.string().required(),
    // category: yup.string().required(),
    // fromDate: yup.date().required(),
    // count: yup.number().required().positive(),
});

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        criteriaMode: "all",
    });
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    // Fetch employee data
    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then((response) => {
            if (response.data.data) {
                return response.data.data.data;
            } else {
                return [];
            }
        });
    };

    // Open Dialog
    const handleClickOpen = () => {
        props.onNew();
    };

    // Close Dialog
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined);
        }, 100);
    };

    // Fetch details for editing
    const fetchDetails = async () => {
        setLoading(true);
        let details = await EmployeeRole.getDetails({ id: props.editId });
        if (details.data.status === "success") {
            let data = details.data.data;
            // setValue('role', data.name);
            setValue('employee', data.employee); 
            setValue('category', data.category); 
            setValue('eligibity_date', data.fromDate); 
            setValue('count', data.count); 
        }
        setLoading(false);
    };

    // On form submission
    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            employee: data.employee,
            category: data.category,
            eligible_date: data.eligible_date,
            count: data.count,
            remarks:data.remarks
        };
        console.log(dataToSubmit);
        
        let action;
        if (props.editId > 0) {
            action = LeaveEligibilityApi.update(dataToSubmit);
        } else {
            action = LeaveEligibilityApi.add(dataToSubmit);
        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                Object.keys(response.data.errors).forEach((key) => {
                    toast.error(response.data.errors[key].name);
                });
                return;
            }
            toast.success(response.data.message);
            props.onUpdate();
            setOpen(false);
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false);
        });
    };

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails();
      setOpen(true);
    } else if (props.editId === 0) {
      reset();
      setOpen(true);
    }
  }, [props.editId]);

    return (
        <div>
            <Button
                className="create-btn"
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <AddIcon sx={{ marginRight: "2px" }} />
                Add Leave
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Leave" : "Create Leave"}</DialogTitle>

                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContent>
                                <Grid container spacing={2}>

                                    {/* Employee Field */}
                                    <Grid item xs={12}>
       
                                         <SelectX
              options={fetchEmployees}
              placeholder="Employee"
              control={control}
              name="employee"
              value={watch('employee')}
            />
                                    </Grid>

                                    {/* Leave Category Field */}
                                    <Grid item xs={12}>
                                    <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              value={watch("category")}
              {...register("category")}
            >
              <MenuItem value="Sick Leave">Sick Leave</MenuItem>
              <MenuItem value="Casual Leave">Casual Leave</MenuItem>
              <MenuItem value="Annual Leave">Annual Leave</MenuItem>
            </Select>
          </FormControl>
                                    </Grid>

                                    {/* Eligible Date Field */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Eligible Date"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            {...register("eligible_date")}
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* Count Field */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Count"
                                            type="number"
                                            {...register("count")}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Remarks"
                                            type="text"
                                            multiline
                                            rows={3}
                                            {...register("remarks")}
                                            fullWidth
                                        />
                                    </Grid>

                                 
                                 

                                    {/* Error Messages */}
                                    <Grid item xs={12}>
                                        {errors && (
                                            <Typography color="error">
                                                {Object.entries(errors).map(([key, error]) => (
                                                    <span key={key}>{error.message}</span>
                                                ))}
                                            </Typography>
                                        )}
                                    </Grid>

                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                            </DialogActions>
                        </form>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default Create;
