import { Button, TextField, Grid, Card, Typography, InputAdornment, IconButton, Box } from "@mui/material";
import PageHeader from "../../common/page-header";
import Create from "./create";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Delete from "../../../../components/Popups/Delete";
import View from "./View";
import { Departments } from "../../../../api/Endpoints/Departments";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment } from "react";
import { Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";
import SearchField from '../../../../components/searchEntry/Index'
const Department = () => {

    const columns = [

        {
            field: 'name', headerName: 'Name', width: 700, renderCell: (params) => (
                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 500, cursor: 'pointer' }}
                    onClick={() => { ViewTabs(params.row.id) }}
                >{params.value}</Typography>

            )
        },

        {
            field: 'edit',
            headerName: 'Edit',

            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)}>
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button>
            ),
        },

        // {
        //     field: 'delete',
        //     headerName: 'Delete',

        //     renderCell: (params) => (

        //         <Button size='small' onClick={() => handleDelete(params.row.id)}>
        //             <DeleteIcon style={{ color: 'red', height: 19 }} />
        //         </Button>

        //     ),
        // },



    ]

    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [showDetails, setDetails] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }

    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const ViewTabs = (id) => {
        setID(id)
        setDetails(true)
    }


    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)
        setTableUpdate(true)
    }

    const clearSerachInput = () => {
        setSearchEntry('');
        setSearchKeyword()
    }
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    const ForTableUpdate = () => {
        setTableUpdate(true)
        fetchTable()

    }
    useEffect(() => {
        fetchTable();
    }, [, searchKeyword, pageNumber])



    const fetchTable = (retryCount = 0) => {
        const maxRetries = 3;
        if (!TableUpdate) {
            setLoading(true)
        }
        Departments.get({
            keyword: searchKeyword,
            page: Number(pageNumber) + 1,
            limit: 20

        }).then(response => {
            setList(response.data.data);
            setLoading(false);
        }).catch(() => {
            if (retryCount < maxRetries) {
                fetchTable(retryCount + 1);
            } else {
                setLoading(false);
                toast.error("An error occurred. Please try again later or contact the administrator.", { autoClose: 6000 })
            }
        }).finally(() => {
            if (TableUpdate) {
                setTableUpdate(false)
            }

        });
    }

    return (

        <>
            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1,ml:2, boxShadow: 0 }}
                className="page_header">
                <PageHeader title={"Departments"} total={list.total} />
                <Create key={refresh} onNew={handleCreate} onUpdate={ForTableUpdate} editId={editId} setEditId={setEditId} />

            </Card >

            {/* {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                showDeletePopup={showDeletePopup}
                Callfunc={ForTableUpdate}
                url="/departments/delete?id="
                title="Department" />} */}

            {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />}

            {!TableUpdate && loading ? (
                <Fragment>
                    <Box id="ui-ball-loader" >
                        <Waveform
                            size={50}
                            lineWeight={3.5}
                            speed={1}
                            color="rgb(63,197,149"
                        />
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Card sx={{ m: 2 }} variant="outlined">
                        <Grid width={'30%'} pt={1}>
                            <SearchField
                                clearSerachInput={clearSerachInput}
                                serachEntry={serachEntry}
                                handleSearchEntry={handleSearchEntry}
                            />

                        </Grid>

                        {list?.data?.length > 0 ? (
                            <Fragment>
                                {typeof list.data === "object" &&
                                    <DataGrid
                                        paginationMode="server"
                                        rows={list?.data}
                                        columns={columns}
                                        page={pageNumber}
                                        pageSize={20}
                                        rowHeight={20}
                                        rowsPerPageOptions={[10]}
                                        rowCount={list.total}
                                        onPageChange={handlePageChange}
                                        autoHeight
                                        density={"comfortable"}
                                        getRowHeight={() => 35}
                                        sx={{ml:1}}
                                    />
                                }
                            </Fragment>
                        ) : (
                            <Box id='data-not-found-msg'>
                                <Typography alignSelf={'center'}>Department not found.</Typography>
                            </Box>
                        )}
                    </Card>
                </Fragment>)}
        </>
        //     )
        // }
        //         </>

    )
};

export default Department;