import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Employee } from '../../../../../api/Endpoints/Employee';
import moment from 'moment';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Backdrop,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { DotSpinner } from '@uiball/loaders';
import Create from '../CreateEmployeeTabs/create';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));


const Details = ({ ID }) => {
    const classes = useStyles();
    const { setUserId, setEmployeeID } = useStateContext();





    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()
    const [editId, setEditId] = useState()
    const [refresh, setRefresh] = useState()

    useEffect(() => {
        fetchDetails()
    }, [refresh])

    const ForTableUpdate = () => {
        setRefresh(Math.random())
    }

    const fetchDetails = async () => {
        setLoading(true);
        setData()
        console.log(ID);
        let EmployeeDetail = await Employee.getEmployeeDetails({ id: ID });
        let data = EmployeeDetail.data.data
        setUserId(data.users_id) // Store the userid in us
        // console.log(data,"details");
        
        setData(data)
        setLoading(false);
    }
    const handleCreate = () => {
        setEditId(ID)

    }
    console.log(Data)
    return (
        <>
            {Loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <DotSpinner
                        size={47}
                        speed={0.9}
                        color="rgb(63,197,149"
                    />
                </Box>
            ) : (
                <>
                    <Grid width={'100%'} display={'flex'} justifyContent={'end'}>
                        <Create
                            changeText="Edit"
                            onUpdate={ForTableUpdate}
                            editId={editId}
                            setEditId={setEditId}
                            onNew={handleCreate}
                        />
                    </Grid>


                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Name:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.name}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Manager:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.manager?.name}
                                        </Typography>
                                    </Typography>

                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Personal  Email :
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.email}
                                        </Typography>
                                    </Typography>

                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Work Email :    <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.email}
                                        </Typography>
                                    </Typography>


                                </Grid>


                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Personal Phone:         <Typography variant="body2"
                                            id='detail-text-inline'>
                                            {Data?.user?.username}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Office Phone:   <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.phone_number}
                                        </Typography>

                                    </Typography>

                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Official Date of Birth:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {moment(Data?.official_date_of_birth).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Celebrated Date:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {moment(Data?.date_of_birth).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Marriage Date:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {moment(Data?.marriage_date).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Joining Date:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {moment(Data?.joining_date).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Relieving Date:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {moment(Data?.releaving_date).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>

                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Employee Level:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.employee_level}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Employee Type:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.employment_status}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Department:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.department?.name}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Role:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.role?.name}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Employee ID:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.employee_ID}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Facebook URL:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.facebook_url}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Instagram URL:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.instagram_url}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Linkedln URL:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.linkedin_url}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Blog URL:
                                        <Typography variant="body2" id='detail-text-inline'>
                                            {Data?.user?.blog_url}
                                        </Typography>
                                    </Typography>


                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Address:
                                    </Typography>
                                    <Typography variant="body2" id='detail-text-inline'>
                                        {Data?.address}
                                    </Typography>
                                </Grid>

                            </Grid>



                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Remarks:
                                    </Typography>
                                    <Typography variant="body2" id='detail-text-inline'>
                                        {Data?.remarks}
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" id='detail-text'>
                                        Reporting Emails:
                                    </Typography>
                                    <Typography variant="body2" id='detail-text-inline'>
                                        {Data?.remarks}
                                    </Typography>

                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;
